import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import HomePage from "../pages/HomePage/HomePage";
import Button from "../components/button/Button";

// Figma Bg: bg-gradient-to-r from-teal-400 to-cyan-500
const MainLayout = () => {
  return (
    <div className="min-h-screen">
      {/* Header Component */}
      <Header />
      {/* Main Content */}
      <main className="bg-transparent">
        <HomePage />

        <section id="products" className="py-12">
          <div className="container mx-auto">
            <div className="grid grid-cols-4 items-start">
              <h2 className="text-3xl font-bold text-gray-800 mb-6">
                Product Categories
              </h2>
              <p className="col-span-2">
                Our product portfolio encompasses a wide range of the latest and
                most advanced ophthalmic products, meticulously designed to
                cater to every aspect of eye care.
              </p>
              <Button
                customClass={"ml-auto text-[4.5vw] lg:text-xl"}
                childrenVal={"View All"}
              />
            </div>
          </div>
        </section>

        <section id="about" className="py-12">
          <div className="container mx-auto">
            <div className="grid grid-cols-4 items-start">
              <h2 className="text-3xl font-bold text-gray-800 mb-6">
                About Us
              </h2>
              <p className="col-span-2 text-lg text-gray-600 mb-12 max-w-2xl mx-auto">
                Established in 1996, My Healthskape Medicals Private Limited has
                been a leader in sourcing ophthalmic surgical equipments and
                diagnostic devices from various reputable international
                suppliers specializing in the ophthalmic industry. We focus on
                bringing the advanced eye care technology to the Indian market,
                ensuring that healthcare professionals have access to the best
                diagnostic equipment, refractive lasers, and surgical
                instruments. Our success is built on a foundation of
                well-trained professionals who provide exceptional service. We
                pride ourselves on being service partners for a lifetime,
                committed to supporting healthcare professionals with the tools
                they need to deliver outstanding patient care.
              </p>
              <Button
                customClass={"ml-auto text-[4.5vw] lg:text-xl"}
                childrenVal={"Know More"}
              />
            </div>
          </div>
        </section>

        <section id="partners" className="py-12">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
              Our Partners
            </h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-8 items-center">
              <img
                src="path/to/logo1.png"
                alt="Partner 1"
                className="mx-auto h-16"
              />
              <img
                src="path/to/logo2.png"
                alt="Partner 2"
                className="mx-auto h-16"
              />
              <img
                src="path/to/logo3.png"
                alt="Partner 3"
                className="mx-auto h-16"
              />
              <img
                src="path/to/logo4.png"
                alt="Partner 4"
                className="mx-auto h-16"
              />
              <img
                src="path/to/logo5.png"
                alt="Partner 5"
                className="mx-auto h-16"
              />
              <img
                src="path/to/logo6.png"
                alt="Partner 6"
                className="mx-auto h-16"
              />
            </div>
          </div>
        </section>

        <section id="speak-to-experts" className="py-12">
          <div className="container mx-auto">
            <div className="grid grid-cols-3">
              <div className="flex flex-col">
                <h2 className="text-3xl font-bold text-gray-800 mb-8">
                  Speak to Our Experts
                </h2>
                <p className="text-justify">
                  Experience advanced technology firsthand. Schedule an
                  in-person demonstration with our expert to discover the
                  benefits our products offer.
                </p>
                <div className="max-w-max mt-vw-5 flex border-2 rounded-full bg-white/10 backdrop-blur-2xl shadow-lg">
                  <button className="text-white p-vw-1 rounded-l-full focus:outline-none hover:bg-black hover:text-teal-400 transition-all">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 19l-7-7 7-7"
                      ></path>
                    </svg>
                  </button>

                  <button className="text-white p-vw-1 rounded-r-full focus:outline-none hover:bg-black hover:text-teal-400 transition-all">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>

              <div className="col-span-2 flex justify-center space-x-6">
                <div className="p-6">
                  <img
                    src="path/to/expert-image.jpg"
                    alt="Expert"
                    className="w-24 h-24 rounded-full mx-auto mb-4"
                  />
                  <p className="text-gray-600 italic mb-4">
                    "The professors say things like ‘this is what you will have
                    to do when you start your business.’"
                  </p>
                  <div class="py-8 px-8 max-w-sm mx-auto bg-white rounded-xl shadow-lg space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                    <img
                      class="block mx-auto h-24 rounded-full sm:mx-0 sm:shrink-0"
                      src="/img/erin-lindford.jpg"
                      alt="Woman's Face"
                    />
                    <div class="text-center space-y-2 sm:text-left">
                      <div class="space-y-0.5">
                        <p class="text-lg text-black font-semibold">
                          Aryna Sabalenka
                        </p>
                        <p class="text-slate-500 font-medium">Designation</p>
                      </div>
                      \
                    </div>
                  </div>
                  {/* <p className="font-bold text-gray-800">Aryna Sabalenka</p> */}
                  {/* <p className="text-sm text-gray-600">Designation</p> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* Footer Component */}
      <Footer />
    </div>
  );
};
export default MainLayout;
