import React from "react";
import HeroSection from "./HeroSection";

const HomePage = () => {
  return (
    <React.Fragment>
      <HeroSection />
    </React.Fragment>
  );
};

export default HomePage;
