// Button.js
// unused-ref-style: text-[4.5vw] lg:text-xl
const Button = ({
  customClass = "",
  childrenVal,
  onClick = () => null,
  rightSymbol = <span className="ml-4 text-[1.5vw] align-middle">&gt;</span>,
}) => {
  return (
    <button
      className={`w-[50vw] lg:w-44 bg-transparent border border-black rounded-full py-[3vw] lg:py-3 shadow-lg text-black hover:bg-black hover:text-teal-400 transition-all ${customClass}`}
      onClick={onClick}
    >
      {childrenVal} {rightSymbol}
    </button>
  );
};
export default Button;
