import React from "react";
import logo from "../../assets/img/app-logo2x.png";

const Header = () => {
  // State to handle drawer visibility
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  // Function to toggle the drawer
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <header className="fixed top-6 left-0 right-0 mx-auto w-11/12 z-50 rounded-md bg-white/10 backdrop-blur-2xl shadow-lg px-6 py-2">
        <nav className="w-full grid grid-cols-3 items-center">
          <div className="justify-self-start flex flex-col items-center max-w-max">
            <div className="w-10">
              <img src={logo} />
            </div>
            <div className="text-sm text-white">HEALTHSKAPE</div>
            <div className="text-xs text-white">MEDICALS PVT. LTD.</div>
          </div>

          <div className="lg:hidden col-span-2 ml-auto flex items-center space-x-4">
            {/* Search button icon */}
            <button className="text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M21 21l-4.35-4.35M17 10.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                />
              </svg>
            </button>
            {/* Burger button drawer icon */}
            <button className="text-white" onClick={toggleDrawer}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>

          <ul className="hidden col-span-2 ml-auto lg:flex items-center gap-20">
            <li>
              <a href="#about" className="text-white hover:text-gray-600">
                About Us
              </a>
            </li>
            <li>
              <a href="#products" className="text-white hover:text-gray-600">
                Our Products
              </a>
            </li>
            <li>
              <a href="#contact" className="text-white hover:text-gray-600">
                Contact
              </a>
            </li>

            <div className="relative">
              <input
                className="pl-10 pr-4 py-1.5 rounded-full bg-transparent border border-white text-white placeholder-white 
            outline-none ring-0 focus:ring-1 focus:ring-white transition-all duration-300 ease-in-out"
                type="search"
                name="search"
                placeholder="Search"
              />
              <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white">
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-4.35-4.35M17 10.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                  ></path>
                </svg>
              </div>
            </div>
          </ul>
        </nav>
      </header>
      <div
        className={`fixed inset-0 z-40 bg-black bg-opacity-50 transition-opacity duration-300 ${
          drawerOpen ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
        onClick={toggleDrawer} // Closes the drawer when backdrop is clicked
      ></div>

      <div
        className={`fixed top-0 left-0 w-2/3 sm:w-1/2 h-full z-50 bg-white transition-transform duration-300 transform ${
          drawerOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="p-4">
          <button onClick={toggleDrawer} className="text-gray-700 mb-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <ul className="space-y-4">
            <li>
              <a href="#products" className="text-gray-800">
                Products
              </a>
            </li>
            <li>
              <a href="#about" className="text-gray-800">
                About Us
              </a>
            </li>
            <li>
              <a href="#contact" className="text-gray-800">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
