import MainLayout from "./layouts/MainLayout";

function App() {
  return (
    <div className="shadow-inner shadow-teal-400 ">
      <MainLayout />
    </div>
  );
}

export default App;
