const Footer = () => {
  return (
    <footer className="w-full bg-transparent border-t border-gray-400 py-8">
      <div className="w-11/12 mx-auto grid grid-cols-1 lg:grid-cols-3 gap-8 items-center">
        {/* Left Section - Logo and Tagline */}
        <div className="text-center lg:text-left">
          <img
            src="/path-to-logo.png"
            alt="HealthSkape Logo"
            className="mx-auto lg:mx-0 w-32"
          />
          <p className="text-lg lg:text-xl text-gray-700 mt-4">
            It’s time to find freedom from pain so you can start living again.
          </p>
        </div>

        {/* Middle Section - Contact Information */}
        <div className="text-center">
          <div className="flex items-center justify-center mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6 text-blue-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M16 12H2m0 0v10m0-10L12 2l10 10M4 14l12-2M4 16l16 2"
              />
            </svg>
            <p className="ml-2 text-gray-700">
              <a href="mailto:help@healthskape.com">help@healthskape.com</a>
            </p>
          </div>
          <div className="flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6 text-blue-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 8l7-3m0 0l7 3m-7-3v12m4-8H6l7 4m0 0v12"
              />
            </svg>
            <p className="ml-2 text-gray-700">
              +91 9876543210 | +91 9876543210
            </p>
          </div>
        </div>

        {/* Right Section - Links */}
        <div className="flex justify-center lg:justify-end">
          <div className="text-center lg:text-right space-y-4">
            <div>
              <a href="/about" className="text-gray-700 hover:text-gray-900">
                About Us
              </a>
            </div>
            <div>
              <a href="/products" className="text-gray-700 hover:text-gray-900">
                Product Categories
              </a>
            </div>
            <div>
              <a
                href="/investors"
                className="text-gray-700 hover:text-gray-900"
              >
                Investors
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Social Media Links */}
      <div className="mt-8 flex justify-center space-x-4">
        <a href="/linkedin">
          <img src="/path-to-linkedin.png" alt="LinkedIn" className="w-6 h-6" />
        </a>
        <a href="/youtube">
          <img src="/path-to-youtube.png" alt="YouTube" className="w-6 h-6" />
        </a>
        <a href="/facebook">
          <img src="/path-to-facebook.png" alt="Facebook" className="w-6 h-6" />
        </a>
        <a href="/twitter">
          <img src="/path-to-twitter.png" alt="Twitter" className="w-6 h-6" />
        </a>
      </div>

      {/* Bottom Footer */}
      <div className="mt-8 w-11/12 mx-auto border-t border-gray-300 py-4 text-center text-sm text-gray-600">
        <p>HealthSkape Medicals Pvt. Ltd. © All Rights Reserved - 2023</p>
        <p>
          Crafted by
          <a
            href="https://togglehead.com"
            className="text-blue-600 hover:text-blue-800 ml-1"
          >
            Togglehead
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
