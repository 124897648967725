import React from "react";
import rightImage from "../../assets/img/hero-sec-right-side-img2x.png";
import Button from "../../components/button/Button";

const HeroSection = () => {
  return (
    <section className="relative h-screen w-full bg-hero-sec-gradient-img">
      <div className="grid grid-cols-1 lg:grid-cols-2 h-full gap-0">
        <div className="flex flex-col justify-center space-y-8 px-8 lg:px-24 lg:text-left">
          <h1 className="text-[6vw] lg:text-[3.5vw] font-bold text-white leading-tight line-clamp-3">
            Bringing Your World Into Focus Dummy Content
          </h1>
          <Button
            customClass={"text-[4.5vw] lg:text-xl"}
            childrenVal={"Know More"}
          />
        </div>

        <div className="relative hidden lg:block">
          <img
            src={rightImage}
            alt="Blurred Vision"
            className="h-screen w-full object-cover rounded-lg filter blur-lg transition duration-300 hover:blur-none"
          />
        </div>

        <div className="relative block lg:hidden">
          <img
            src={rightImage}
            alt="Blurred Vision"
            className="h-[50vh] w-full object-cover rounded-lg filter blur-lg transition duration-300 hover:blur-none"
          />
        </div>
      </div>

      <div className="hidden lg:block absolute bottom-8 left-8 px-vw-5">
        <div className="flex items-center space-x-2">
          <div className="flex justify-center w-8 h-12 border-2 border-white rounded-full pt-vw-0.6">
            <span className="w-2 h-2 rounded-full bg-white animate-bounce"></span>
          </div>
          <p className="text-white">Scroll Down</p>
        </div>
      </div>

      <div className="flex absolute bottom-14 right-24 border-2 rounded-full bg-white/10 backdrop-blur-2xl shadow-lg  ">
        <button className="text-white p-vw-1 rounded-l-full focus:outline-none hover:bg-black hover:text-teal-400 transition-all">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
        </button>

        <button className="text-white p-vw-1 rounded-r-full focus:outline-none hover:bg-black hover:text-teal-400 transition-all">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            ></path>
          </svg>
        </button>
      </div>
    </section>
  );
};

export default HeroSection;
